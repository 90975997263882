<template>
  <div>
    <b-row>
      <b-col>
        <div ref="table-container">
          <small class="text-muted"
            >Current Page: {{ currentPage }}. Total rows: {{ rows }}</small
          >
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            size="sm"
            align="right"
            limit="10"
            v-if="rows > 0"
          ></b-pagination>
          <section class="my-3 d-inline-block">
            <b-row>
              <b-col>
                <small class="text-muted ml-1">Show/Hide Columns </small>
                <treeselect
                  multiple
                  value-consists-of="LEAF_PRIORITY"
                  v-model="selectedColumns"
                  :options="availableColumns"
                />
              </b-col>
            </b-row>
          </section>
          <b-table
            bordered
            small
            :responsive="true"
            striped
            :items="items"
            :fields="visibleColumns"
            :per-page="perPage"
            :current-page="currentPage"
            :busy="isBusy"
            class="chat-histories-table table-viewer"
          >
            <template v-slot:table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
              <slot :name="slot" v-bind="props" />
            </template>

            <!--        <template v-slot:cell(3)="data"-->
            <!--          >{{ data.item[3] | datetimelong }}-->
            <!--        </template>-->
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            size="sm"
            align="right"
            limit="10"
            v-if="rows > 0"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'TheTableViewer',
  props: {
    fields: {
      type: Array,
    },
    items: {
      type: Array,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.selectedColumns = this.fields.map((a) => a.key);
  },
  watch: {
    fields() {
      this.selectedColumns = this.fields.map((a) => a.key);
    },
  },
  data() {
    return {
      selectedColumns: [],
      perPage: 100,
      currentPage: 1,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
    availableColumns() {
      return [
        {
          id: 'a',
          label: 'All',
          children: this.fields.map((a) => {
            return { id: a.key, label: a.label };
          }),
        },
      ];
    },
    visibleColumns() {
      const selectedColumns = this.selectedColumns;
      return this.fields.filter((a) => {
        return selectedColumns.includes(a.key);
      });
    },
  },
};
</script>

<style scoped>
.table-viewer {
  font-size: 0.9em;
}
</style>