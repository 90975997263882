<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <h1>API Usages</h1>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col class="col-6 col-lg-2 mb-3 mb-lg-0">Bot</b-col>
      <b-col cols="4">
        <bot-selector
          @botChanged="
            (botId) => {
              searchParams.botId = botId;
            }
          "
        />
      </b-col>
      <b-col></b-col>
      <b-col></b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col class="col-6 col-lg-2 mb-3 mb-lg-0">Chat ID</b-col>
      <b-col class="col-6 col-lg-2 mb-3 mb-lg-0">
        <b-form-input v-model="searchParams.chatId" size="sm" />
      </b-col>
      <b-col class="col-6 col-lg-2 mb-3 mb-lg-0">Member Code</b-col>
      <b-col class="col-6 col-lg-2 mb-3 mb-lg-0">
        <b-form-input v-model="searchParams.memberCode" size="sm" />
      </b-col>
      <b-col class="col-6 col-lg-2 mb-3 mb-lg-0">API Name</b-col>
      <b-col class="col-6 col-lg-2 mb-3 mb-lg-0">
        <b-form-input v-model="searchParams.apiName" size="sm" />
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col class="col-6 col-lg-2 mb-3 mb-lg-0">Response Status</b-col>
      <b-col class="col-6 col-lg-2 mb-3 mb-lg-0">
        <b-form-select
          v-model="searchParams.responseStatus"
          size="sm"
          :options="[
            { value: null, text: 'Select' },
            { value: true, text: 'Success' },
            { value: false, text: 'Failed' },
          ]"
        />
      </b-col>
      <b-col class="col-6 col-lg-2 mb-3 mb-lg-0">DateTime</b-col>
      <b-col class="col-6 col-lg-4 mb-3 mb-lg-0">
        <date-range v-model="dateRange" />
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-button
          variant="primary"
          size="sm"
          class="float-right mx-2"
          @click="getItems"
        >
          <b-icon-search />
          Search
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="float-right mx-2"
          @click="exportSummary"
        >
          <b-icon-file-earmark-excel />
          Export
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="items.length">
      <b-col>
        <b-table-lite
          bordered
          small
          :responsive="true"
          striped
          :items="apiCounts"
          :fields="[
            { key: 'apiName' },
            { key: 'responseStatus' },
            { key: 'count' },
          ]"
        >
          <template v-slot:cell(apiName)="data">
            <div class="cell-button" @click="showDetail(data)">
              {{ data.value }}
            </div>
          </template>
          <template v-slot:cell(responseStatus)="data">
            {{ data.value ? 'Success' : 'Failed' }}
          </template>
        </b-table-lite>
      </b-col>
    </b-row>

    <b-modal
      ref="modal-detail"
      size="xl"
      hide-footer
      centered
      lazy
      :title="selectedApiName"
    >
      <b-row>
        <b-col>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mx-2"
            @click="exportDetails"
          >
            <b-icon-file-earmark-excel />
            Export
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <the-table-viewer
            :fields="fields"
            :items="itemsByApiName"
            :is-busy="isBusy"
          >
            <template v-slot:cell(dateTime)="data"
              >{{ data.value | datetimelong }}
            </template>
            <template v-slot:cell(chatId)="data">
              <div
                class="text-truncate cell-button"
                style="max-width: 100px"
                @click="searchChatId(data.value)"
              >
                {{ data.value }}
              </div>
            </template>
            <template v-slot:cell(requestData)="data">
              <div
                class="text-truncate cell-button"
                style="max-width: 220px"
                @click="showJsonViewer(data.value)"
              >
                {{ data.value }}
              </div>
            </template>
            <template v-slot:cell(responseData)="data">
              <div
                class="text-truncate cell-button"
                style="max-width: 220px"
                @click="showJsonViewer(data.value)"
              >
                {{ data.value }}
              </div>
            </template>
            <template v-slot:cell(responseStatus)="data">
              {{ data.value ? 'Success' : 'Failed' }}
            </template>
          </the-table-viewer>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      ref="modal-json-viewer"
      size="lg"
      centered
      hide-header
      hide-footer
      lazy
    >
      <json-viewer :value="parseJson(jsonString)" />
    </b-modal>

    <b-modal
      size="lg"
      centered
      scrollable
      hide-footer
      ref="chat-modal"
      header-class="chat-id-header"
    >
      <template v-slot:modal-title>
        <span>Chat ID:</span>
        <input
          ref="chat-id"
          class="chat-id"
          style="cursor: pointer"
          readonly
          @click="selectChatId"
          :value="chatId"
        />
      </template>
      <chat-text :chats="chats" />
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import BotSelector from '@/components/UI/BotSelector';
import TheTableViewer from '@/components/UI/TheTableViewer';
import HttpHandler from '@/mixins/HttpHandler';
import JsonViewer from '@/components/UI/JsonViewer';
import DateRange from '@/components/UI/DateRange';
import dayjs from 'dayjs';
import ChatText from '@/components/ChatHistories/ChatText';

export default {
  name: 'ApiUsagesView',
  mixins: [HttpHandler],
  components: { BotSelector, TheTableViewer, DateRange, JsonViewer, ChatText },
  data() {
    return {
      searchParams: {
        botId: null,
        dateTimeFrom: null,
        dateTimeTo: null,
        memberCode: null,
        chatId: null,
        apiName: null,
        responseStatus: null,
      },
      dateRange: {
        startDate: dayjs().startOf('day').toDate(),
        endDate: dayjs().add(1, 'days').startOf('day').toDate(),
      },
      fields: [
        {
          key: 'apiUsageId',
          label: 'ID',
          sortable: false,
        },
        {
          key: 'dateTime',
          label: 'DateTime',
          sortable: true,
        },
        {
          key: 'memberCode',
          label: 'Member Code',
          sortable: true,
        },
        {
          key: 'chatId',
          label: 'Chat ID',
          sortable: false,
        },
        {
          key: 'apiName',
          label: 'API',
          sortable: true,
          thStyle: 'min-width: 200px',
        },
        {
          key: 'requestData',
          label: 'Request',
          sortable: true,
        },
        {
          key: 'responseData',
          label: 'Response',
          sortable: true,
        },
        {
          key: 'responseStatus',
          label: 'Response Status',
          sortable: true,
        },
        {
          key: 'responseMessage',
          label: 'Response Message',
          sortable: true,
        },
      ],
      items: [
        // {
        //   apiUsageId: 1,
        //   dateTime: '2022-04-25',
        //   botId: 30,
        //   memberCode: 'testing member 1',
        //   chatId: 'testing chat Id 1',
        //   webhookId: null,
        //   intentId: null,
        //   apiName: 'testing api name 1',
        //   requestData: '{"testing":"testing"}',
        //   responseData: '{"testingResp":"testingResp"}',
        //   responseStatus: true,
        //   responseMessage: 'this is message 1',
        // },
      ],
      apiCounts: [],
      isBusy: false,

      jsonString: '',
      selectedApiName: '',
      selectedResponseStatus: '',
      chatId: '',
      chats: [],
    };
  },
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    this.searchParams.botId = botId;
  },
  computed: {
    itemsByApiName() {
      const items = _.cloneDeep(this.items);
      return items.filter(
        (a) =>
          a.apiName === this.selectedApiName &&
          a.responseStatus === this.selectedResponseStatus
      );
    },
  },
  methods: {
    getItems() {
      let searchParams = _.cloneDeep(this.searchParams);
      let dateRange = _.cloneDeep(this.dateRange);
      searchParams.dateTimeFrom = dayjs(dateRange.startDate).format(
        'YYYY-MM-DD'
      );
      searchParams.dateTimeTo = dayjs(dateRange.endDate).format('YYYY-MM-DD');
      this.httpclient
        .get('api/apiUsages', { params: searchParams })
        .then((resp) => {
          this.items = resp.data;
        });
      this.httpclient
        .get('api/apiUsages/summary', { params: searchParams })
        .then((resp) => {
          let apiCounts = resp.data;
          apiCounts = _.orderBy(apiCounts, ['count'], ['desc']);
          this.apiCounts = apiCounts;
        });
    },
    exportSummary() {
      let searchParams = _.cloneDeep(this.searchParams);
      let dateRange = _.cloneDeep(this.dateRange);
      searchParams.dateTimeFrom = dayjs(dateRange.startDate).format(
        'YYYY-MM-DD'
      );
      searchParams.dateTimeTo = dayjs(dateRange.endDate).format('YYYY-MM-DD');
      this._downloadFileAxios('api/apiUsages/summary/export', {
        params: searchParams,
      });
    },
    exportDetails() {
      let searchParams = _.cloneDeep(this.searchParams);
      let dateRange = _.cloneDeep(this.dateRange);
      searchParams.dateTimeFrom = dayjs(dateRange.startDate).format(
        'YYYY-MM-DD'
      );
      searchParams.dateTimeTo = dayjs(dateRange.endDate).format('YYYY-MM-DD');
      searchParams.apiName = this.selectedApiName;
      searchParams.responseStatus = this.selectedResponseStatus;
      this._downloadFileAxios('api/apiUsages/export', {
        params: searchParams,
      });
    },
    parseJson(jsonString) {
      try {
        return JSON.parse(jsonString);
      } catch (e) {
        return {};
      }
    },
    showJsonViewer(jsonString) {
      this.jsonString = jsonString;
      this.$refs['modal-json-viewer'].show();
    },
    showDetail(data) {
      const row = data.item;
      this.selectedApiName = row.apiName;
      this.selectedResponseStatus = row.responseStatus;
      this.$refs['modal-detail'].show();
    },
    searchChatId(chatId) {
      this.chatId = chatId;

      this.httpclient
        .post('api/chathistories/getlist', { chatId: chatId, limit: 1000 })
        .then((resp) => {
          this.chats = resp.data;
          this.$refs['chat-modal'].show();
        })
        .catch(() => {
          this.chats = [];
        });
    },
    selectChatId() {
      this.$refs['chat-id'].focus();
      this.$refs['chat-id'].select();
      navigator.clipboard.writeText(this.chatId);
    },
  },
};
</script>

<style scoped>
.cell-button {
  cursor: pointer;
  color: #0b58a2;
  text-decoration: underline;
}
</style>
