<template>
  <b-select size="sm" v-model="botId" :options="bots"></b-select>
</template>

<script>
/*global _*/
import http from '@/mixins/HttpHandler';

export default {
  name: 'BotSelector',
  mixins: [http],
  data() {
    return {
      bots: [],
    };
  },
  computed: {
    botId: {
      get() {
        return this.$store.state.selectedBotId;
      },
      set(botId) {
        this.$store.commit('updateSelectedBotId', botId);
        const botName = _.find(this.bots, { value: botId }).text;
        this.$store.commit('updateSelectedBotName', botName);
        this.$emit('botChanged', botId, botName);
      },
    },
  },
  mounted() {
    this.httpclient.get('/api/security/bots').then((resp) => {
      this.bots = _.map(resp.data, function (item) {
        return {
          value: item.botId,
          text: item.botName,
        };
      });

      if (this.bots.length > 0 && this.botId === null) {
        this.botId = this.bots[0].value;
      }
    });
  },
  methods: {},
};
</script>

<style scoped></style>
